.header {
  background-color: #CFB7B1;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  width: 100%;
}

@media (max-width: 600px) {
  .header-content {
      flex-direction: row;
      position: static;
      bottom: auto;
  }

  .App-header {
      justify-content: flex-start;
      padding-top: 20px;
  }
}