.project-grid {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: left;
  width: 100%;
}

.project-item {
  cursor: pointer;
  border: none;
  width: 13vw;
  padding: 1vw;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.project-item:hover {
  transform: translateY(-10px);
}

.project-item.active {
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
  transform: translateY(-30px);
}

.project-item .project-image {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.project-item .project-image img {
  max-height: 100%;
}

.project-details {
  padding: 20px;
  font-size: 3vh;
  text-align: left;
}

.project-details h3 {
  margin-top: 0;
}

@media screen and (max-width: 800px) {
  .project-item {
    width: 100%;
  }

  .project-grid {
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}