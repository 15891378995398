@keyframes move {
  0% { transform: translate(0, 0); }
  100% { transform: translate(var(--random-x, 10px), var(--random-y, 10px)); }
}

.background-icons img {
  opacity: 40%;
  position: absolute;
  z-index: 0;
  height: 10vh;
  animation: move var(--random-duration, 100s) infinite alternate;
  z-index: 0;
}

@media (max-width: 600px) {
  .background-icons img {
    height: 10vh;
  }
}
