.section {
    padding: 4vh;
    display: flex;
    flex-direction: row;
    gap: 1vw;
  }
  
.section-header{
    display: flex;
    flex-direction: row;
    align-items: top;
    gap: 1vw;
    width: 30vw;
}

.section-header img{
    width: 10vh;
    height: 10vh;
}

.section-header h1{
    font-size: 2.5vh;
}

.section-content{
    display: flex;
    flex-direction: row;
    align-items: top;
    justify-content: left;
    width: 70vw;
}

.section-content img{
    width: 10vh;
    height: 10vh;
}

.section-content div{
    gap: 1vw;
}

@media screen and (max-width: 800px) {
    .section {
        flex-direction: column;
        gap: 1vh;
    }

    .section-header{
        width: 100%;
    }
    .section-content{
        width: 100%;
        justify-content: center;
        align-items: center;
    }
}