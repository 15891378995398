.Connect-content {
    display:flex;
    flex-direction:row;
    gap:10rem;
    justify-content:left;
    padding-left:2rem;
}

@media screen and (max-width: 800px) {
    .Connect-content {
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 1vh;
    }
    
}