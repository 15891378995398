.certifications-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    justify-content: left;
    padding: 20px;
    background-color: var(--background-color2);
}

.certification-card {
    border-radius: 10px;
    padding: 1vw;
    text-align: center;
    transition: transform 0.3s, box-shadow 0.3s;
    cursor: pointer;
    overflow: hidden;
}

.certification-card img {
    width: 20rem;
    height: 10rem;
    margin-bottom: 10px;
}

.certification-card h3 {
    margin-bottom: 10px;
    font-size: 1.2rem;
    color: var(--text-color);
}

.certification-card:hover {
    transform: translateY(-10px);
  }

.certification-card.active {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.certification-description p{
    margin-top: 10px;
    font-size: 3vh;
    color: var(--text-color);
    text-align: left;
}


@media screen and (max-width: 800px) {
    .certification-card {
        width: 100%;
    }

    .certification-card img {
        width: 100%;
        height: auto;
    }
    
}