.Navigation-icons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 8vw;
    padding-top: 1vh;
    margin-top: -0.7vh;
    width: 100%;
    background-color: #98807F;
    z-index: 2;
  }
  
  .Navigation-icons a {
    position: relative;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10vh;
    height: 10vh;
    perspective: 1000vh;
  }
  
  .Navigation-icons img {
    width: 100%;
    height: 100%;
    transition: transform 0.5s;
    backface-visibility: hidden;
  }
  
  .Navigation-icons a::before {
    content: attr(data-text);
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: #1A222B;
    background-color: rgba(255, 255, 255, 0.1);
    font-size: 1.9vh;
    text-align: center;
    border-radius: 5px;
    transform: rotateY(180deg);
    backface-visibility: hidden;
    transition: transform 0.5s;
  }
  
  .Navigation-icons a:hover img {
    transform: rotateY(180deg);
  }
  
  .Navigation-icons a:hover::before {
    transform: rotateY(0deg);
  }

  @media screen and (max-width: 800px){
    .Navigation-icons {
      gap: 1vh;
      height: calc(8vh + 1vh);
    }
    
    .Navigation-icons a {
      width: 8vh;
      height: 8vh;
    }
    
    .Navigation-icons img {
      width: 70%;
      height: 70%;
    }
    
    .Navigation-icons a::before {
      font-size: 1.5vh;
    }
  }