:root {
  --background-color1: #CFB7B1; 
  --background-color2: #98807F; 
  --text-color: #1A222B;
}

.App {
  text-align: center;
  color: var(--text-color);
}

/* Scroll to top button styles */
.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #CFB7B1;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  transition: opacity 0.3s;
  opacity: 0.7;
}

.scroll-to-top:hover {
  opacity: 1;
}


@media screen and (max-width: 800px) {
  .scroll-to-top {
    bottom: 10px;
    right: 10px;
    position: fixed;
  }
}