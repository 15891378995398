.skills-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    justify-content: left;
    padding: 20px;
  }
  
  .skill-card {
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 320px;
    text-align: left;
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .skill-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .skill-card h3 {
    margin-bottom: 15px;
    font-size: 1.5rem;
    color: #333;
  }
  
  .skill-card ul {
    list-style-type: none;
    padding: 0;
  }
  
  .skill-card li {
    margin: 10px 0;
    font-size: 1rem;
    position: relative;
  }
  
  .skill-name {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .skill-bar-container {
    background-color: #eee;
    border-radius: 5px;
    overflow: hidden;
    height: 10px;
    margin-top: 5px;
  }
  
  .skill-bar {
    height: 100%;
    border-radius: 5px;
    transition: width 0.3s ease;
  }
  
  @media screen and (max-width: 800px) {
    .skill-card {
      width: 100%;
    }
  }