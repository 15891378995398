.bio-frame {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
  width: 100%;
  box-sizing: border-box;
  z-index: 1;
  padding-left: 5vw;
  padding-right: 5vw;
}

.bio {
  width: 100%;
}

.bio-img {
  width: 50%;
  height: auto;
}

@media (max-width: 800px) {
  .bio-frame {
    flex-direction: column;
  }

  .bio {
    width: 100%;
  }

  .bio-img {
    width: 100%;
  }
}
