.logo-container{
    display: flex;
    justify-content: left;
    position: absolute;
    width: 10rem;
    margin-left: 1rem;
}

.timeline-icon{
    width: 100%;
    height: 100%;
}

@media (max-width: 1100px) {
    .timeline-icon{
        width: 15rem;
    }
    
}